<template>
  <b-container fluid class="user-page p-0">
    <b-row>
      <b-col cols="12">
        <div class="box-user" ref="boxUser">
          <div class="user pb-0">
            <div class="avatar">
              <img src="~@/assets/images/logo/big_logo.png" alt="" />
            </div>
            <div class="info ml-4">
              <div class="name">
                ID: <span>{{ UserInfo.id }}</span>
              </div>
              <div class="d-flex">
                <div class="email">
                  Email:
                  <span v-if="this.$refs.boxUser" v-b-tooltip.hover :title="UserInfo.email">
                    {{ UserInfo.email }}
                  </span>
                </div>

                <div
                  class="verify-status d-none d-sm-flex"
                  :key="UserKYC.status"
                  :class="{ verify: UserKYC.status == 1 && UserInfo.name }"
                >
                  <div class="text-status">
                    {{ UserKYC.status == 1 && UserInfo.name ? 'Verified' : 'Unverifying' }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="verify-status d-flex d-sm-none"
            :key="UserKYC.status"
            :class="{ verify: UserKYC.status == 1 && UserInfo.name }"
          >
            <div class="text-status">
              {{ UserKYC.status == 1 && UserInfo.name ? 'Verified' : 'Unverifying' }}
            </div>
          </div>
          <div class="more-action">
            <div class="nav-item-profile">
              <a :class="{ active: boxActive == 'VerifyBox' }" @click="showComponent('VerifyBox')">
                <i class="fas fa-id-card"></i>
                <span> Verification </span>
              </a>
            </div>
            <div class="divide"></div>
            <div class="nav-item-profile">
              <a :class="{ active: boxActive == 'AuthBox' }" @click="showComponent('AuthBox')">
                <i class="far fa-user-shield"></i>
                <span> Two Factor Authentication </span>
              </a>
            </div>
            <div class="divide"></div>
            <div class="nav-item-profile">
              <a :class="{ active: boxActive == 'ChangeBox' }" @click="showComponent('ChangeBox')">
                <i class="fad fa-dice-d4"></i>
                <span> Change Password </span>
              </a>
            </div>
          </div>
        </div>
      </b-col>
      <b-col cols="12" class="content-profile pr-lg-0 mt-4">
        <component :is="boxActive" :key="`box-profile-${boxActive}`" :ref="boxActive"></component>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex';

import VerifyBox from '@/components/profile/VerifyBox.vue';
import AuthBox from '@/components/profile/AuthBox.vue';
import ChangeBox from '@/components/profile/ChangeBox.vue';

export default {
  components: { VerifyBox, AuthBox, ChangeBox },
  data() {
    return {
      kyc_status: false,
      boxActive: 'VerifyBox',
      email: 'violet200945@gmail.com',
      widthp: 100,
    };
  },
  computed: {
    ...mapGetters({
      UserInfo: 'user/UserInfo',
      UserKYC: 'user/UserKYC',
    }),
  },
  watch: {},
  methods: {
    scrollToElement(elm) {
      const el = this.$refs[elm];

      if (el) {
        // Use el.scrollIntoView() to instantly scroll to the element
        el.scrollIntoView({ behavior: 'smooth' });
      }
    },
    showComponent(box) {
      this.boxActive = box;
      this.scrollToElement(box);
    },
  },
  created() {
    this.$store.dispatch('user/req_getKYC');
  },
  mounted() {},
};
</script>

<style lang="scss">
.user-page {
  .content-profile {
    @media (max-width: 991px) {
      margin-top: 40px;
    }
  }
  .box-user {
    width: 100%;
    min-height: 175px;
    // background-color: #fff;
    // max-width: 540px;
    border-radius: 10px;
    // box-shadow: 0px 7px 10px 2px #00000038;
    position: relative;
    .verify-status {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #b9454c;
      padding: 4px 24px;
      line-height: 20px;
      // padding: 0 15px 0 5px;
      @media (max-width: 575px) {
        max-width: 125px;
        width: 100%;
        margin-left: auto;
      }
      margin-left: 2rem;
      .text-status {
        font-size: clamp(10px, 2.5vw, 12px) !important;
      }
      &.verify {
        background: #b9454c;
        .text-status {
          font-size: clamp(10px, 2.5vw, 12px) !important;
        }
        .icon-status {
          background: rgba(255, 255, 255, 0.527);
        }
      }
      .icon-status {
        width: 30px;
        height: 30px;
        // background: rgba(255, 255, 255, 0.527);
        border: 1px solid rgba(255, 255, 255, 0.8);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        i,
        svg {
          width: 100%;
          height: 100%;
          color: #ccc;
        }
      }
      .text-status {
        color: #fff;
        font-weight: 600;
        font-size: 18px;
      }
    }
    .user {
      // padding: 1.25rem;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      align-items: center;
      @media (max-width: 1440px) {
        padding: 10px;
      }
      .avatar {
        background: none;
        max-width: 100%;
        width: 85px;
        height: 85px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        img {
          width: 100%;
          height: auto;
        }
        @media (max-width: 1440px) {
          width: 65px;
          height: 65px;
        }
        @media (max-width: 767px) {
          width: 45px;
          height: 45px;
        }
      }
      .info {
        width: 100%;
        .name {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          color: #6c757d;
          margin-left: 5px;
          font-weight: 600;
          font-size: clamp(16px, 2vw, 17px);
          text-transform: uppercase;
          span {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            color: #2f394e !important;
            font-weight: 600;
            font-size: clamp(15px, 2vw, 18px);
            margin-left: 10px;
          }
        }
        .email {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          color: #6c757d;
          margin-left: 5px;
          font-weight: 600;
          font-size: clamp(14px, 2vw, 15px);
          text-transform: uppercase;
          span {
            color: #2f394e !important;
            font-weight: 600;
            font-size: clamp(15px, 2vw, 18px);
            margin-left: 10px;
            text-transform: lowercase;
            display: inline-block;
            white-space: nowrap;
            overflow: hidden !important;
            text-overflow: ellipsis;
            max-width: calc(100% - 50px);
          }
        }
      }
    }
    .more-action {
      padding: 1.25rem 0;
      display: flex;
      justify-content: flex-start;
      @media (max-width: 991px) {
        justify-content: space-between;
      }
      @media (max-width: 768px) {
        overflow: scroll !important;
      }
      .divide {
        width: 1px;
        height: 25px;
        margin: auto 15px;
        background: #888888;
      }
      .nav-item-profile {
        margin: 10px 45px 10px 0;
        @media (max-width: 991px) {
          margin: 10px 0;
        }
        a {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          border-left: 3px solid transparent;
          -webkit-transition: all 0.3s ease-out;
          transition: all 0.3s ease-out;
          text-decoration: none;
          padding: 7px 20px;
          cursor: pointer;
          @media (max-width: 991px) {
            padding: 0;
          }
          i,
          svg {
            width: 30px;
            display: inline-block;
            font-size: 20px;
            opacity: 0.8;
            vertical-align: middle;
            color: #a8a8b1;
            margin-right: 5px;
          }
          span {
            display: block;
            color: #404656;
            @media (max-width: 991px) {
              max-width: 175px;
              width: 100%;
            }
          }
          &.active {
            span {
              color: #23222f;
            }
            font-weight: 600;
            border-bottom: 1px solid #ff5c00;
            // background: linear-gradient(89deg, #ff5c0014, #8dc63f1f);
          }
        }
      }
    }
  }
}
</style>
